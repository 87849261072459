<template>
  <div class="">
    <p class="text-orange-700" v-if="error">{{error}}</p>

    <form @submit.prevent="registerUser" class="space-y-6">
      <InputOverlappingLabel class="mb-5" required="required" label="Name" placeholder="John Smith" id="username" name="username"  autocomplete="username" type="text" v-model="username"/>
      <InputOverlappingLabel class="my-5" required="required" label="Email" placeholder="exemple@au-vrac.fr" id="email" name="email" autocomplete="email" type="email" v-model="email"/>
      <InputOverlappingLabel class="my-5" required="required" label="Mot de passe" id="password" name="password" autocomplete="new-password" type="password" v-model="password"/>
      <button type="submit"
        class="relative w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        :disabled="loading"
      >
        Créer mon compte
        <HoverSpinner color="blue" v-if="loading"/>
      </button>
    </form>
  </div>
</template>
<script setup>
import InputOverlappingLabel from "~/components/forms/InputOverlappingLabel.vue";
import {ref} from "vue";
import {useMessage} from "~/src/functions/useMessages";
import HoverSpinner from "~/components/layout/HoverSpinner.vue";
import {useStrapiUserStore} from '~/stores/strapi-user';

const userStore = useStrapiUserStore();
const router = useRouter()
const emit = defineEmits(['success'])
const loading = ref(false);
const {message: error, showAfter: showErrorMessageAfter, show: showErrorMessage} = useMessage();

const username = ref('');
const email = ref('');
const password = ref('');
const registerUser = () => {
  loading.value = true;
  const requestTimeout = setTimeout(() => {
    loading.value = false;
    showErrorMessageAfter('Le serveur à mis trop de temps à répondre.', 0, 5);
  }, 5000)

  userStore.register({username: username.value, email: email.value, password: password.value})
    .then(
      () => {
        emit('success');
        clearTimeout(requestTimeout);
        loading.value = false;
      },
      (err) => {
        clearTimeout(requestTimeout);
        showErrorMessage(err);
        loading.value = false;
        console.error(err+'');
      }
    );
}


</script>
